/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useMaterialUIController } from "/context";
import dynamic from "next/dynamic";
import Image from 'next/image';
import Link from "next/link";
import bgPattern from "/assets/img/pattern-lines.svg";
import img1 from "/assets/img/patioConfigurator/cb20x20-patio-above.jpg";
import img2 from "/assets/img/patioConfigurator/cb20x25-patio-firepit-grill-back.jpg";
import img3 from "/assets/img/patioConfigurator/cb20x30-patio-firepit-wall-grill-left.jpg";
import { robotoSlab } from "/utils/fonts";

const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const SettingsRounded = dynamic(() => import('@mui/icons-material/SettingsRounded'), { loading: () => null });
const PhoneEnabledRounded = dynamic(() => import('@mui/icons-material/PhoneEnabledRounded'), { loading: () => null });
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

                  
                  
                  

function ExampleCard({ image, name }) {
  return (
    <Link href="/patio-configurator">
      <MDBox>
        <MDBox
          bgColor="white"
          borderRadius="xl"
          shadow="lg"
          minHeight="max-content"
          sx={{
            overflow: "hidden",
            transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
            transformOrigin: "50% 0",
            backfaceVisibility: "hidden",
            willChange: "transform, box-shadow",
            transition: "transform 200ms ease-out",
            // cursor: "pointer",
            "&:hover": {
              transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
            },
          }}
          p={0.5}
          display="grid"
        >
          <Image
            src={image}
            alt="view mode"
            sizes="(max-width: 768px) 428px, (max-width: 1000px) 147px, 226px"
            style={{ objectFit: "cover", width: "100%", height: "auto", borderRadius: 8 }}
          />
        </MDBox>
        <MDBox mt={1} ml={1} lineHeight={1}>
          <MDTypography variant="h6" component="h3" fontWeight="bold" color="white">
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Link>
  );
}

function PatioConfigurator() {
  const [controller] = useMaterialUIController();
  return (
    <MDBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 12 }} mx={-2} sx={{ overflow: "hidden !important" }}>
      <Image
        id="my-work"
        src={bgPattern.src}
        alt="background-pattern"
        fill
        style={{
          opacity: 0.6, top: 0, left: 0, transform: 'scale(2)',
        }}
      />
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            lg={4}
            sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}
          >
            <MDTypography variant="h2" color="white" mb={3} className={robotoSlab.className}>
              Configure Your Patio Online
            </MDTypography>
            <MDTypography variant="body2" color="white" mb={2} opacity={0.8} fontWeight="regular">
              If you're looking for an affordable patio, we have patio packages with
              configurable sizes and optional features. You can configure your patio online.
              We can install the patio configuration you design or use it as a starting point for a larger project.
            </MDTypography>
            <MDBox display="flex" alignItems="center" sx={{ alignItems: "center" }}>
              <Link href="/patio-configurator">
                <MDButton
                  startIcon={<SettingsRounded color="white" />}
                  variant="gradient" color="info" sx={{ mt: 3, mb: 2, mr: 1 }}>
                  Configure patio online
                </MDButton>
              </Link>
              <MDButton
                href="tel:1+631-424-5544"
                startIcon={<PhoneEnabledRounded color="success" />}
                variant="text"
                fontWeight={300}
                color="white"
                sx={{ mt: 3, mb: 2, mr: 1 }}
              >
                {controller.mobileView === false && "631-424-5544"}
                {controller.mobileView === true && "Call"}
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ pl: { xs: 3, lg: 6 }, pr: { xs: 3, lg: 6 }, pb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4} sx={{ mt: { xs: 0, lg: 6 } }}>
                <ExampleCard
                  image={img1}
                  name="view from above" />
              </Grid>
              <Grid item xs={12} lg={4} sx={{ mt: { xs: 0, lg: 6 } }}>
                <ExampleCard
                  image={img2}
                  name="view from the back"
                />
              </Grid>
              <Grid item xs={12} lg={4} sx={{ mt: { xs: 0, lg: 6 } }}>
                <ExampleCard
                  image={img3}
                  name="view from the side"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default PatioConfigurator;
